@use '../../../global/styles/base/sizing' as *;
@use '../../../global/styles/base/colors';
@use '../../../global/styles/base/mixins';

$bg-opacity: 0.15;
$bg-opacity-hover: 0.3;

.tag{
  border-radius: $l;
  box-sizing: border-box;
  width: max-content;
  position: relative;
  min-width: 60px;
  display: inline-flex;
  text-align: center;
  align-self: flex-start;
  flex-shrink: 0;
  flex-grow: 0;
  font-weight: 600;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  
  &.xs{
    padding: 1px 8px;
    font-size: 12px;
    min-width: 50px;
  }
  &.sm{
    padding: 2px 8px;
    font-size: 12px;
  }
  &.md{
    padding: 4px 6px;
  }
  &.lg{
    padding: 8px 12px;
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: $bg-opacity;
    z-index: 0;
    border-radius: inherit;
  }

  @include mixins.tagBG(var(--span-color), $bg-opacity-hover);

  &.whiteOnDark{
    color: var(--white-on-dark);
    &::before {
      background: var(--white-on-dark);
    }
    
    &:hover{
      &::before {
        opacity: $bg-opacity-hover;
      }
    }
  }
}