@use '../../../global/styles/base/sizing' as *;
@use '../../../global/styles/base/responsive';

.container{
  display: flex;
  border-radius: 20px;
  margin-bottom: $xl;

  // gradient border
  background: 
    linear-gradient(var(--background-color), var(--background-color)) padding-box,
    linear-gradient(140deg, var(--primary) 0%, var(--tertiary)) border-box;
  border-radius: 20px;
  border: 6px solid transparent;

  @include responsive.tablet {
    min-height: 350px;
    justify-content: center;
    align-items: center;
  }

  @include responsive.mobile {
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h1, h2, h3, h4, h5{
    color: white;
    line-height: 1.2em;
    background-image: linear-gradient(90deg, var(--primary) 0%, var(--tertiary) 100%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
}

.infoContainer{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  column-gap: $m;
  row-gap: $m;

  @include responsive.mobile {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  
  .infoThird{
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    padding: $xl;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 160px;
    border-radius: $b-rad;

    @include responsive.mobile {
      padding: $m;
    }
    
    p, h3{
      text-align: center;
    }
    p{
      color: var(--text-color-weak);
    }

    &:not(.strokeSVG) {
      svg{
        margin-bottom: $xs;
        
        > g, path, rect {
          fill: var(--primary);
        }
      }
    }

    &.strokeSVG svg{
      stroke: var(--primary);
    }
  }
}

.beginningContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $xl;

  @include responsive.mobile {
    padding: $m 0;
  }
}

.ctaContainer{
  padding: 100px $l;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @include responsive.mobile {
    flex-flow: column nowrap;
    padding: 140px 0;
  }
  
}